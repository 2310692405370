import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import PdfButton from "../ui/pdf-button";
import SecondaryLinks from "../ui/secondary-links";
import Button from "../ui/button";
import ApplyNow from "../ui/apply-now-button";
import ScheduleTour from "../ui/schedule-button";
import { calculateResponsiveSize } from 'helpers/calculateResponsiveSize'
import { useEffect } from "react";
import {
  Wrapper,
  Logo,
  Col,
  Center,
  Address,
  Links,
  LinkItem,
  WLogo,
  Icons,
  Icon,
  Row,
  Cookie,
} from "./index.styled";

const Footer = () => {
  const {
    dataJson: {
      footerInfo: { address, phone, links, instalink },
    },
  } = useStaticQuery(graphql`
    query {
      dataJson {
        footerInfo {
          address
          phone
          instalink
          links {
            title
            link
          }
        }
      }
    }
  `);

  useEffect(() => {
    const jQuery = document.createElement("script");
    jQuery.src = "https://code.jquery.com/jquery-3.7.0.min.js";
    jQuery.id = "jQuery";
    jQuery.async = true;
    document.head.appendChild(jQuery);

    const script = document.createElement("script");
    script.src = "https://multifamily.hinesdni.com/js/dni.js";
    script.id = "DNI-js";
    script.async = true;
    document.head.appendChild(script);

    const dniScript = document.createElement("script");
    dniScript.innerHTML = `
  function  call_dni() {
    if (typeof dni_format === 'undefined') {
        dni_format = "xxx.xxx.xxxx";
    }
    var propertyid = "1314357";
    var format = dni_format;
    if (typeof call_dni_function == 'function') {
        call_dni_function(propertyid, format);
    }
}

call_dni();
  `;
    dniScript.id = "dniscript-js";
    document.head.appendChild(dniScript);

    return () => {
      const existingScript = document.getElementById("DNI-js");
      if (existingScript) {
        document.head.removeChild(existingScript);
      }
      const existingDni = document.getElementById("dniscript-js");
      if (existingDni) {
        document.head.removeChild(existingDni);
      }
      const existingjQuery = document.getElementById("jQuery");
      if (existingjQuery) {
        document.head.removeChild(existingjQuery);
      }
    };
  });

  return (
    <>
      <Wrapper className={"hideMob"}>
        <Col>
          <PdfButton />
          <WLogo to="/" />
        </Col>
        <Center>
          <Logo />
          <Address>
            {address}
            <br></br>
            <a className="dni_tel_link dni_phone_number" href={`tel:${phone}`}>
              {phone}
            </a>
          </Address>
        </Center>
        <Col className={"last"}>
          <SecondaryLinks />
          <button
      className="cmp-revoke-consent"
      style={{   color: '#151515',
position:'static',
        background:'none',
        appearance:'none',
        border:'none',
        padding:'0',
        cursor:'pointer',
       textTransform:'uppercase',
       fontFamily: '"TT Norms Pro 500"',
       fontWeight: '500',
       fontSize: `${calculateResponsiveSize(9)}`,
      }}
    >
      Manage Cookie Preferences
    </button>
          <Icons>
            <a href={instalink} target="_blank">
              <Icon className={"instagram"} />
            </a>
            <a
              href="https://www.hines.com/real-estate-services/product-types/living/americas-fair-housing-statement"
              target="_blank"
            >
              <Icon className={"housing"} />
            </a>
            <a href="https://www.hines.com/accessibility" target="_blank">
              <Icon className={"ada"} />
            </a>
            <Icon className={"no-smoking"} />
            <Icon className={"pet-friendly"} />
          </Icons>
        </Col>
      </Wrapper>
      <Wrapper className={"hideWeb"}>
        <Logo />
        <Row className={"center"}>
          <PdfButton />
          {/*  <ApplyNow className={['black'].join(' ')} />
          <ScheduleTour className={'black'} /> */}
        </Row>
        <Row>
          <Address>
            {address}
            <br></br>
            <a className="dni_tel_link dni_phone_number" href={`tel:${phone}`}>
              {phone}
            </a>
          </Address>
          <SecondaryLinks />
          <button
      className="cmp-revoke-consent"
      style={{   color: '#151515',
position:'static',
        background:'none',
        appearance:'none',
        border:'none',
        padding:'0',
        cursor:'pointer',
       textTransform:'uppercase',
       fontFamily: '"TT Norms Pro 500"',
       fontWeight: '500',
       fontSize: '9px',
       paddingLeft:'5px',
       paddingTop:'3px'
      }}
    >
      Manage Cookie Preferences
    </button>
        </Row>
        <Row>
          <WLogo />
          <Icons>
            <a href={instalink} target="_blank">
              <Icon className={"instagram"} />
            </a>
            <a
              href="https://www.hines.com/real-estate-services/product-types/living/americas-fair-housing-statement"
              target="_blank"
            >
              <Icon className={"housing"} />
            </a>
            <a href="https://www.hines.com/accessibility" target="_blank">
              <Icon className={"ada"} />
            </a>
            <Icon className={"no-smoking"} />
            <Icon className={"pet-friendly"} />
          </Icons>
        </Row>
      </Wrapper>
    </>
  );
};

export default Footer;
